<template>
    <div style="background-color: #FFFFFF;width: 100%;">
        <div style="padding-top: 8%;"></div>
        <div class="payresult-content">
            <div class="img1"><img src="@/assets/images/yizhifu.png"/></div>
            <div class="text1">购买成功</div>
            <div class="inner">
                <div class="text2">温馨提示：</div>
                <div class="text3" v-if="auth_type<3">
                        您的会员已开通，添加授权主体后即可前往会员专区&音效库免费下载音乐作品<br>
                        若您关闭此页面，稍后也可前往“个人中心-我的会员”页面添加授权主体</div>
                <div class="text3" v-if="auth_type==3">
                        您的省钱卡已开通，当前省钱卡所包含优惠券权益已发送至您的账户，可前往个人中心-我的优惠券中查看</div>
            </div>
            <div class="bottom">
                    <button class="button1" @click="addAuth" v-if='auth_type<3'>添加授权主体</button>
                    <button class="button1" @click="gomusic" v-if="auth_type==3">前往购买音乐</button>
                    <button class="button2" @click="golist">查看我的订单</button>
            </div>
        </div>
        <div style="height: 260px;"></div>
        <auth  @close='onClose()' v-if="showAuth" :order_id='order_id' :auth_type='auth_type'></auth>
    </div>
</template>
<script>
    import auth from '../member/vip/auth.vue'
    export default {
        data() {
            return {
                auth_type:1,
                order_id: this.$route.params.order_id,
                showAuth:false,
            }
        },
        components: {
            auth
        },
        created: function () {
            this.viporderInfo();
        },
        computed: {

        },
        methods: {
            onClose(){
                this.showAuth = false;
                this.$router.push({path:'/member_v2?menukey=4'});
            },
            viporderInfo(){
                let params = {
                    'order_id': this.order_id,
                };
                this.$axios.viporderInfo(params).then(res => {
                    if(res.data.code == 0){
                        this.auth_type = res.data.data.vip_info[0].auth_type;
                    }
                })
            },
            golist(){
                this.$router.push({path:'/member_v2?menukey=4'});
            },
            gomusic(){
                this.$router.push({path:'/allmusic?id=1'});
            },
            addAuth(){
                this.showAuth = true;
            },
            onAdd(){
                // console.log("---------")
                this.showAuth = true;
            },
        },
    }
</script>
<style lang="scss" scoped>
.payresult-content{
    width: 80%;
    margin: 0 auto;
    .img1{
        width:64px;
        margin:0 auto;
        /* left:50%;
        margin-left:-32px; */
        /* margin-top:200px; */

    }
    .text1{
        width:100%;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        margin-top:17px;
    }
    .text2{
        padding-top:17px;
        margin-left:36px;
        color: #FF8729;
        font-size: 14px;
    }
    .text3{
        margin-top:17px;
        margin-left:36px;
        /* color: #FF8729; */
        font-size: 14px;
        width:85%;
    }
    .inner{
        width:44%;
        margin-top:42px;
        margin-left:28%;
        height:140px;
        background-color: #F4F6F6;
    }
    .bottom{
        width:44%;
        margin-top:42px;
        margin-left:28%;
        height:44px;
        margin-bottom: 260px;
    }
    .button1{
        margin-left:26%;
        width: 23%;
        height: 44px;
        background: linear-gradient(180deg, #2B9EFF 0%, #3F96E1 100%);
        border-radius: 4px;
        border:none;
        color:white;
    }
    .button2{
        width: 23%;
        margin-left:5%;
        height: 44px;
        border-radius: 4px;
        border: 1px solid #3B98E7;
        color:#3D98E5;
    }
}
</style>